<template>
  <div class="wrap-modal-common" @click.self="$emit('close-modal', $event)">
    <div class="content-modal">
      <!-- CLOSE -->
      <figure @click="$emit('close-modal', $event)"
              class="icon-close">
        <img src="../../assets/images/icon/icon-close.svg" alt="icon close">
      </figure>

      <!-- CONTENT BODY -->
      <slot name="body"></slot>

      <!-- FOOTER -->
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal'
}
</script>

<style lang="scss" scoped>
</style>
