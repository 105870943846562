<template>
  <section class="cate-orange">
    <div class="profile-common">
      <!-- info overview -->
      <div class="wrap-overview">
        <div class="content-info">
          <!-- name -->
          <h2>{{ profile.nickname }}<span>さん</span></h2>

          <!-- point -->
          <div class="point-user">
            <!-- availability points -->
            <div>
              <p>利用可能ポイント</p>
              <strong v-if="user_point.total_point_amount < 10000000">
                {{ user_point.total_point_amount | numberShort }}P
              </strong>
              <strong v-else>
                {{ 9999999 | numberWithCommas }}+P
              </strong>
            </div>

            <!-- waiting point for approval -->
            <div>
              <p>判定中ポイント</p>
              <strong>
                {{ (infoStatisticCommon.waiting_pointback_amount || 0) | numberShort(',', 'P') }}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <!-- invite friend -->
      <section class="section-profile">
        <!-- title -->
        <h3 class="title">
          <img src="../../assets/images/icon/icon-users.svg" alt="icon">お友達紹介
        </h3>

        <!-- show info -->
        <div class="content-invite">
          <div class="info">
            <p>今月の紹介人数</p>
            <strong>{{ infoStatisticCommon.total_invitee_in_month }}<span>人</span></strong>
          </div>
          <div class="info">
            <p>累計紹介人数</p>
            <strong>{{ profile.invite_friend_amount || 0 }}<span>人</span></strong>
          </div>
          <div class="invite-friend">
            <router-link to="/invite-friend">
              <img src="../../assets/images/icon/icon-user-invite.svg" alt="icon">お友達に紹介する
            </router-link>
          </div>
        </div>
      </section>
    </div>

    <div class="section-profile">
      <!-- title -->
      <h3 class="title">
        <img src="../../assets/images/icon/icon-book-open.svg" alt="icon">ポイント通帳
      </h3>

      <!-- tab point detail -->
      <div class="wrap-tab-point">
        <div class="menu-tab">
          <router-link to="/profile/mypage">
            <list-icon size="1.5x" class="custom-class"></list-icon>ポイント明細
          </router-link>

          <router-link to="/profile/point-exchange-history" class="active">
            <img src="../../assets/images/icon/icon-history-active.svg" alt="icon">ポイント交換履歴
          </router-link>
        </div>

        <!-- content tab detail point -->
        <p class="note">※過去6ヶ月分まで表示</p>

        <!-- message data empty -->
        <p class="data-empty"
           v-if="pagination.total === 0">該当するデータが見つかりませんでした。</p>

        <table v-else
               class="table-common-profile">
          <tr>
            <th>ステータス</th>
            <th>申請日</th>
            <th>交換完了日</th>
            <th>交換先サービス名</th>
            <th>ポイント</th>
          </tr>
          <tr v-for="item in exchangeHistory"
              :key="item.id">
            <td>
              <p class="sp">ステータス</p>
              <img :src="require('../../assets/images/icon/' + displayImgStatus(item.status))" alt="icon">
              <span>{{ displayStatus(item.status) }}</span>
            </td>
            <td>
              <p class="sp">申請日</p>
              {{ item.request_date | formatDate('MM/DD') }}
            </td>
            <td>
              <p class="sp">交換完了日</p>
              {{ item.approval_date | formatDate('MM/DD') }}
            </td>
            <td>
              <figure>
                <img :src="require('../../assets/images/icon/' + displayImgService(item.service_id))" alt="thumbnail">
              </figure>
              <p class="line-clamp-01">{{ getServiceName(item) }}</p>
            </td>
            <td>
              <div class="btn-detail-wrapper">
                <button class="btn-detail" @click="handleRow(item)">詳細</button>
                <div class="exchange-history-point">{{ item.point | numberShort(',', 'P') }}</div>
              </div>
            </td>
          </tr>
        </table>

        <!-- pagination -->
        <div v-if="pagination.totalPages > 1"
             class="wrap-pagination">
          <Pagination :total-page="pagination.totalPages"
                      @change-page = changePage
                      :current-page="pageActive" />
        </div>
      </div>
    </div>

    <!-- MODAL EXCHANGE POINT HAS VOUCHER -->
    <Modal @close-modal="flagModalHasCode = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalHasCode">
      <div class="content-body"
           slot="body">
        <h3>{{ getServiceName(dataModal) }}</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ dataModal.point | numberWithCommas }}<span class="color-orange sp" v-html="displayUinit(dataModal.service_id)"></span></strong>
            <time
              :class="{ 'time-request': true, 'no-bottom': dataModal.service_id === exchangePointService.auPay.id}"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ dataModal.request_date | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                :class="{ 'unit-gpoint' : dataModal.service_id === exchangePointService.gPoint.id }"
                v-html="displayUinit(dataModal.service_id)"></span>
        </div>

        <div class="voucher">
          <span
            class="font-weight-bold"
            v-if="dataModal.service_id === exchangePointService.gPoint.id && dataModal.code"
          >
            Ｇポイントお問合せ番号
          </span>

          <span
            class="font-weight-bold"
            v-if="dataModal.service_id === exchangePointService.giftPad.id && dataModal.code"
          >
            ギフトコード
          </span>

          <span
            class="font-weight-bold"
            :class="{ 'prepaid-number': isRenderCodeAndCodeCrypt }"
            v-if="isRenderCodeAndCodeCrypt && dataModal.code_crypt"
          >
            プリペイド番号
          </span>

          <p
            class="mb-4"
            v-if="isRenderCodeAndCodeCrypt && dataModal.code_crypt"
          >
            {{ dataModal.code_crypt }}
          </p>

          <span
            class="font-weight-bold"
            v-if="dataModal.service_id === exchangePointService.quoPay.id && dataModal.code"
          >
            バリューコード
          </span>

          <span
            class="font-weight-bold"
            v-if="isRenderCodeAndCodeCrypt && dataModal.code"
          >
            管理番号
          </span>

          <p
            class="mb-2"
            v-if="isRenderCode && dataModal.code"
          >
            {{ dataModal.code }}
          </p>

          <time
            class="font-weight-bold"
            v-if="dataModal.service_id === exchangePointService.gPoint.id && dataModal.expiration_datetime"
          >
            Ｇポイントギフト有効期限：
            <span>
              {{ dataModal.expiration_datetime | formatDate('YYYY年MM月DD日') }}
            </span>
          </time>

          <time
            class="font-weight-bold"
            v-if="(dataModal.service_id === exchangePointService.quoPay.id || isRenderCodeAndCodeCrypt) && dataModal.expiration_datetime"
          >
            有効期限：
            <span>
              {{ dataModal.expiration_datetime | formatDate('YYYY年MM月DD日') }}
            </span>
          </time>
        </div>
        <!-- NOTE MODAL GPOINT -->
        <p
          class="note"
          v-if="dataModal.service_id === exchangePointService.gPoint.id && dataModal.code"
        >
          ※「ポイントを受取りに行く」ボタンからＧポイントサイトへ遷移しＧポイントをお受け取りください。<br>
          ※お受け取りいただけない場合は、
          <a
            class="link-orange"
            href="https://support.gpoint.co.jp/hc/ja/requests/new?ticket_form_id=4416570678297"
            target="_blank"
          >
            Ｇポイントのお問い合わせフォーム
          </a>
          より上記「Ｇポイントお問合せ番号」を記載のうえお問い合わせください。
        </p>

        <!-- NOTE MODAL GIFTPAD -->
        <p
          class="note gift-pad"
          v-if="dataModal.service_id === exchangePointService.giftPad.id && dataModal.code"
        >
          ※ポイントを受け取りに行くボタンからギフトパッドに遷移いただくか、<br>
          上記のギフトコードを
          <a
            class="link-orange"
            href="https://giftpad.jp/login/"
            target="_blank"
          >
            ギフトパッド
          </a>
          でご入力ください。
        </p>

        <!-- NOTE MODAL QUOPAY -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.quoPay.id && dataModal.code"
        >
        「QUOカードPayへ移動」ボタンからQUOカードPayへ遷移し<br class="pc">QUOカードPayをお受け取りください
        </p>

        <!-- NOTE MODAL AUPAY -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.auPay.id && dataModal.code"
        >
        「au PAY ギフトカードへ移動」ボタンからau PAY ギフトカード受け取りページへ遷移しau PAY ギフトカードをお受け取りください
        </p>

        <!-- NOTE MODAL Tully -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.tully.id && isExchangeAutoSuccess"
        >
        「タリーズデジタルギフトを表示」ボタンからギフトをお受け取りください
        </p>

        <!-- NOTE MODAL KFC -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.kfc.id && isExchangeAutoSuccess"
        >
        「デジタルKFC CARDを表示」ボタンからギフトをお受け取りください
        </p>

        <!-- NOTE MODAL Yoshinoya -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.yoshinoya.id && isExchangeAutoSuccess"
        >
        「𠮷野家デジタルギフトを表示」ボタンからギフトをお受け取りください
        </p>

        <!-- NOTE MODAL Movie Walker -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.movieWalker.id && isExchangeAutoSuccess"
        >
        「映画GIFTを表示」ボタンからギフトをお受け取りください
        </p>
      </div>
      <div class="btn-footer"
           slot="footer">
        <!-- LINK TO GIFT PAD OR G-POINT -->
        <a target="_blank"
           v-if="(dataModal.service_id === exchangePointService.giftPad.id || dataModal.service_id === exchangePointService.gPoint.id) && dataModal.code"
           :href="dataModal.service_id === exchangePointService.giftPad.id ? linkGiftPad + dataModal.code : linkGPoint + dataModal.code_crypt"
           class="btn-common">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを受取りに行く
        </a>

        <!-- LINK TO QUOPAY -->
        <a class="btn-common"
           :href="dataModal.url"
           v-if="dataModal.service_id === exchangePointService.quoPay.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">QUOカードPayへ移動
        </a>

        <!-- LINK TO AUPAY -->
        <a class="btn-common"
           target="_blank"
           :href="linkAuPay"
           v-if="dataModal.service_id === exchangePointService.auPay.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">au PAY ギフトカードへ移動
        </a>

        <!-- LINK TO Tully -->
        <a class="btn-common"
           target="_blank"
           :href="dataModal.url"
           v-if="dataModal.service_id === exchangePointService.tully.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">タリーズデジタルギフト
        </a>

        <!-- LINK TO KFC -->
        <a class="btn-common"
           target="_blank"
           :href="dataModal.url"
           v-if="dataModal.service_id === exchangePointService.kfc.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">デジタルKFC CARD
        </a>

        <!-- LINK TO Yoshiyama -->
        <a class="btn-common"
           target="_blank"
           :href="dataModal.url"
           v-if="dataModal.service_id === exchangePointService.yoshinoya.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">𠮷野家デジタルギフト
        </a>

        <!-- LINK TO Movie walker -->
        <a class="btn-common"
           target="_blank"
           :href="dataModal.url"
           v-if="dataModal.service_id === exchangePointService.movieWalker.id && isExchangeAutoSuccess">
          <img src="../../assets/images/icon/icon-coins.svg" alt="icon">映画GIFT
        </a>
      </div>
    </Modal>

    <!-- MODAL EXCHANGE POINT DOT MONEY, LINE PAY -->
    <Modal
      @close-modal="flagModalNoCode = false"
      class="modal-exchange-point modal-center"
      v-if="flagModalNoCode"
    >
      <div
        class="content-body"
        slot="body"
      >
        <h3>{{ dataModal.service_name }}</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>
              {{ dataModal.point | numberWithCommas }}
              <span class="sp color-orange">
                {{ displayUinit(dataModal.service_id) }}
              </span>
            </strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ dataModal.request_date | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange">
            {{ displayUinit(dataModal.service_id) }}
          </span>
        </div>
        <!-- TEXT DOTMONEY -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.dotMoney.id && isExchangeAutoSuccess"
        >
          ※「ドットマネーby Amebaへ移動」ボタンからドットマネーのサイトへ遷移しドットマネーをお受け取りください。
        </p>

        <!-- TEXT LINEPAY -->
        <p
          class="text-instruct"
          v-if="dataModal.service_id === exchangePointService.linePay.id && isExchangeAutoSuccess"
        >
          実際に送金が完了しますと、「LINEウォレット」の公式アカウントへ、<br class="pc">送金完了の通知が届きます。
        </p>
      </div>

      <div
        class="btn-footer"
        slot="footer"
      >
        <!-- BTN DOT MONEY -->
        <button
          class="btn-common"
          v-if="dataModal.service_id === exchangePointService.dotMoney.id && isExchangeAutoSuccess"
          @click="redirectUrl"
        >
          ドットマネー by Amebaへ移動
        </button>
      </div>
    </Modal>
  </section>
</template>

<script>
import { ListIcon } from 'vue-feather-icons'
import Pagination from '@/components/Pagination'
import Modal from '@/components/advertising-detail/Modal'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import { EXCHANGE_POINT_STATUS } from '@/enum/options'
import Common from '@/mixins/common.mixin'
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export default {
  name: 'PointExchangeHistory',

  components: {
    Modal,
    Pagination,
    ListIcon
  },

  mixins: [Common],

  beforeRouteEnter (to, from, next) {
    const params = {
      perPage: 12,
      page: 1
    }
    store.dispatch('user/getExchangeHistory', params).then(_ => next())
  },
  computed: {
    ...mapState('auth', ['profile', 'user_point']),
    ...mapState('user', ['infoStatisticCommon', 'pagination', 'exchangeHistory', 'dataGift']),

    exchangePointService () {
      return EXCHANGE_POINT_SERVICE
    },

    isRenderCodeAndCodeCrypt () {
      return this.dataModal.service_id === this.exchangePointService.auPay.id
    },

    isRenderCode () {
      return (
        this.dataModal.service_id !== this.exchangePointService.tully.id &&
        this.dataModal.service_id !== this.exchangePointService.kfc.id &&
        this.dataModal.service_id !== this.exchangePointService.yoshinoya.id &&
        this.dataModal.service_id !== this.exchangePointService.movieWalker.id
      )
    },

    isExchangeAutoSuccess () {
      return this.dataModal.status === 4 || this.dataModal.status === 7
    }
  },

  data () {
    return {
      flagModalHasCode: false,
      flagModalGet: false,
      flagModalNoCode: false,
      dataModal: {},
      params: {
        page: 1,
        perPage: 12
      },
      pageActive: 1,
      linkGPoint: process.env.VUE_APP_LINK_GPOINT,
      linkGiftPad: process.env.VUE_APP_LINK_GIFTPAD,
      linkAuPay: process.env.VUE_APP_LINK_AUPAY
    }
  },

  methods: {
    ...mapActions('user', ['getExchangeHistory', 'getGift']),
    ...mapActions('dotMoney', ['loginDotMoney']),

    displayImgStatus (status) {
      switch (status) {
        case 0:
          return 'icon-status-00.svg' // waiting
        case 1:
          return 'icon-status-01.svg' // approve
        case 2:
          return 'icon-status-02.svg' // reject
        case 4:
          return 'icon-status-04.svg' // success
        case 5: // fail
        case 6: // requesting
          return 'icon-status-08.svg'
        case 7: // cancel
        case 9: // invalid
          return 'icon-status-02.svg'
        default:
          return 'icon-status-06.svg'
      }
    },

    displayImgService (serviceId) {
      switch (serviceId) {
        case 1:
          return 'service-01.svg' // gift pad
        case 2:
          return 'service-02.svg' // gpoint
        case 3:
          return 'service-03.svg' // dot money
        case 4:
          return 'service-04.png' // linepay
        case 5:
          return 'service-05.png' // quopay
        case 6:
          return 'service-06.png' // aupay
        case 7:
          return 'service-07.png' // tully
        case 8:
          return 'service-08.png' // kfc
        case 9:
          return 'service-09.png' // yoshinoya
        case 10:
          return 'service-10.png' // movie walker
        default:
          return 'service-sample.jpeg'
      }
    },

    displayUinit (serviceId) {
      switch (serviceId) {
        case 1:
          return 'ptギフト'
        case 2:
          return 'Ｇポイント<br>（Ｇポイントギフト）'
        case 3:
          return 'マネー'
        case 4:
        case 5:
        case 6:
          return '円相当'
        default:
          return 'マネー'
      }
    },

    getServiceName (history) {
      return history?.service_name || ''
    },

    displayStatus (status) {
      if (!status) {
        return '判定中'
      } else {
        const exchange = EXCHANGE_POINT_STATUS.find(x => x.prefecture_code === status)
        if (!exchange) {
          return ''
        } else {
          return exchange.status
        }
      }
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.getExchangeHistory(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    handleRow (data) {
      this.dataModal = data
      // show modal dotmoney, linepay
      if (this.displayModalNoCode(data.service_id)) {
        this.flagModalNoCode = true
      } else {
        // show modal gpoint, gifpad, quopay
        this.flagModalHasCode = true
      }
    },

    displayModalNoCode (serviceID) {
      // modal dotmoney, linepay
      return [3, 4].includes(serviceID)
    },

    redirectUrl () {
      const windowReference = window.open()
      this.loginDotMoney().then((result) => {
        windowReference.location = result.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-common-profile {
  td {
    //cursor: pointer;
  }
}
.data-empty {
  text-align: center;
  padding: 20px 15px;
  font-weight: bold;
}
.link-orange {
  color: #C66C44;
  text-decoration: underline;
}
.prepaid-number {
  margin-top: 30px;
  @media #{$info-phone} {
    margin-top: 20px;
  }
}
.note.gift-pad {
  text-align: center;
  margin-top: 30px;
  @media #{$info-phone} {
    margin-top: 25px;
  }
}
.time-request {
  &.no-bottom {
    margin-bottom: 0;
  }
}

.wrap-tab-point .table-common-profile tr td:nth-child(4) figure img {
  width: 100%;
  height: 100%;
}
</style>
