export const OPTION_YEAR = [
  {
    id: 1,
    text: '2020',
    value: '2020'
  },
  {
    id: 2,
    text: '2021',
    value: '2021'
  },
  {
    id: 3,
    text: '2022',
    value: '2022'
  }
]

export const OPTION_GENDER = [
  {
    id: 1,
    text: '女性',
    value: 2
  },
  {
    id: 2,
    text: '男性',
    value: 1
  },
  {
    id: 4,
    text: 'その他',
    value: 4
  },
  {
    id: 3,
    text: '未回答',
    value: 3
  }
]

export const OPTION_SECRET_QUESTION = [
  {
    id: 1,
    text: 'あなたが初めて飼ったペットの名前は何ですか？',
    value: 1
  },
  {
    id: 2,
    text: 'あなたが初めて所有した車のモデルは何ですか？',
    value: 2
  },
  {
    id: 3,
    text: 'あなたの出身地はどこですか？',
    value: 3
  },
  {
    id: 4,
    text: 'お父さんのファーストネームは何ですか？',
    value: 4
  },
  {
    id: 5,
    text: 'あなたの子供の頃のニックネームは何ですか？',
    value: 5
  },
  {
    id: 6,
    text: 'あなたの好きな歌は何ですか？',
    value: 6
  },
  {
    id: 7,
    text: 'あなたの好きな食べ物は何ですか？',
    value: 7
  },
  {
    id: 8,
    text: 'あなたが卒業した小学校名は何ですか？',
    value: 8
  }
]

export const OPTION_CAREER = [
  {
    id: 1,
    text: '学生',
    value: 1
  },
  {
    id: 2,
    text: '会社員',
    value: 2
  },
  {
    id: 3,
    text: '公務員',
    value: 3
  },
  {
    id: 4,
    text: '経営者・役員',
    value: 4
  },
  {
    id: 5,
    text: '自営業',
    value: 5
  },
  {
    id: 6,
    text: '自由業（フリーランス）',
    value: 6
  },
  {
    id: 7,
    text: '専業主婦・主夫',
    value: 7
  },
  {
    id: 8,
    text: '家事手伝い',
    value: 8
  },
  {
    id: 9,
    text: '無職',
    value: 9
  }
]

export const OPTION_INCOME_OF_FAMILY = [
  {
    id: 1,
    text: '200万円未満',
    value: 1
  },
  {
    id: 2,
    text: '200万円以上400万円未満',
    value: 2
  },
  {
    id: 3,
    text: '400万円以上600万円未満',
    value: 3
  },
  {
    id: 4,
    text: '600万円以上800万円未満',
    value: 4
  },
  {
    id: 5,
    text: '800万円以上1,000万円未満',
    value: 5
  },
  {
    id: 6,
    text: '1,000万円以上1,200万円未満',
    value: 6
  },
  {
    id: 7,
    text: '1,200万円以上1,500万円未満',
    value: 7
  },
  {
    id: 8,
    text: '1,500万円以上2,000万円未満',
    value: 8
  },
  {
    id: 9,
    text: '2,000万円以上',
    value: 9
  }
]

export const OPTION_MARRIAGE = [
  {
    id: 1,
    text: '既婚',
    value: 1
  },
  {
    id: 2,
    text: '未婚',
    value: 2
  },
  {
    id: 3,
    text: '未回答',
    value: 3
  }
]

export const OPTION_RESIDENCE = [
  {
    id: 1,
    text: '持ち家（一戸建て）',
    value: 1
  },
  {
    id: 2,
    text: '持ち家（マンションなど集合住宅）',
    value: 2
  },
  {
    id: 3,
    text: '賃貸（一戸建て）',
    value: 3
  },
  {
    id: 4,
    text: '賃貸（マンションなど集合住宅）',
    value: 4
  },
  {
    id: 5,
    text: '社宅・寮・シェアハウスなど',
    value: 5
  },
  {
    id: 6,
    text: 'その他',
    value: 6
  }
]

export const OPTION_INTEREST = [
  {
    id: 1,
    text: 'ファッション・コスメ',
    value: 1
  },
  {
    id: 2,
    text: '美容・健康',
    value: 2
  },
  {
    id: 3,
    text: '音楽鑑賞',
    value: 3
  },
  {
    id: 4,
    text: '映画鑑賞',
    value: 4
  },
  {
    id: 5,
    text: '芸術・美術',
    value: 5
  },
  {
    id: 6,
    text: '読書',
    value: 6
  },
  {
    id: 7,
    text: '旅行・観光',
    value: 7
  },
  {
    id: 8,
    text: 'アウトドア',
    value: 8
  },
  {
    id: 9,
    text: 'グルメ',
    value: 9
  },
  {
    id: 10,
    text: 'ゲーム',
    value: 10
  },
  {
    id: 11,
    text: '車・バイク',
    value: 11
  },
  {
    id: 12,
    text: 'その他',
    value: 12
  }
]

export const OBJECT_INTEREST = {
  1: 'ファッション・コスメ',
  2: '美容・健康',
  3: '音楽鑑賞',
  4: '映画鑑賞',
  5: '芸術・美術',
  6: '読書',
  7: '旅行・観光',
  8: 'アウトドア',
  9: 'グルメ',
  10: 'ゲーム',
  11: '車・バイク',
  12: 'その他'
}

export const OPTIONS_DELETE_USER = [
  {
    id: 1,
    title: 'サイトの使い方がよく分からない',
    value: 1
  },
  {
    id: 2,
    title: '提携先サービス・ショップが少ない',
    value: 2
  },
  {
    id: 3,
    title: '興味・時間がなくなった',
    value: 3
  },
  {
    id: 4,
    title: 'ポイントが貯まりにくい',
    value: 4
  },
  {
    id: 5,
    title: 'メールマガジンが多い',
    value: 5
  },
  {
    id: 6,
    title: 'ポイント付きのメールマガジンやアンケートが少ない',
    value: 6
  },
  {
    id: 7,
    title: 'メールマガジンの内容がつまらない',
    value: 7
  },
  {
    id: 8,
    title: '解除できないメールマガジンがあるため',
    value: 8
  },
  {
    id: 9,
    title: '交換先に不満があった',
    value: 9
  },
  {
    id: 10,
    title: 'ポイントに有効期限があるため',
    value: 10
  },
  {
    id: 11,
    title: '1ポイント＝0.5円とわかりにくい',
    value: 11
  },
  {
    id: 12,
    title: '他サイトの方が良かった',
    value: 12
  },
  {
    id: 13,
    title: '問合せの対応が遅い・悪い',
    value: 13
  },
  {
    id: 14,
    title: 'PCサイトのサービスに不満があった',
    value: 14
  },
  {
    id: 15,
    title: 'スマホサイトのサービスに不満があった',
    value: 15
  },
  {
    id: 16,
    title: 'その他',
    value: 16
  }
]

export const AFAD_STATUS = [
  {
    id: 1,
    prefecture_code: 0,
    status: '判定中' // waiting
  },
  {
    id: 2,
    prefecture_code: 1,
    status: '承認済み' // approved
  },
  {
    id: 3,
    prefecture_code: 2,
    status: '否認' // reject
  },
  {
    id: 4,
    prefecture_code: 3,
    status: '申請中' // requesting
  },
  {
    id: 5,
    prefecture_code: 8,
    status: '判定中' // Chibijob checking
  }
]

export const EXCHANGE_POINT_STATUS = [
  {
    id: 1,
    prefecture_code: 4,
    status: '交換済み' // success
  },
  {
    id: 2,
    prefecture_code: 5,
    status: '申請中' // fail but we display requesting
  },
  {
    id: 3,
    prefecture_code: 6,
    status: '申請中' // requesting
  },
  {
    id: 4,
    prefecture_code: 7,
    status: 'キャンセル' // cancel
  },
  {
    id: 5,
    prefecture_code: 9,
    status: '無効' // invalid
  }
]

export const SERVICE_NAME = [
  {
    id: 1,
    prefecture_code: 1,
    name: 'Gift Pad' // gift pad
  },
  {
    id: 2,
    prefecture_code: 2,
    name: 'Gポイント' // G point
  },
  {
    id: 3,
    prefecture_code: 3,
    name: 'ドットマネー' // dot money
  },
  {
    id: 4,
    prefecture_code: 4,
    name: 'LINE Pay残高' // line pay
  },
  {
    id: 5,
    prefecture_code: 5,
    name: 'Quopay' // quo pay
  },
  {
    id: 6,
    prefecture_code: 6,
    name: 'au PAY ギフトカード' // au pay
  }
]

export const SCRIPT_OSTIARIES = {
  urlOverseas: 'https://asp.ostiaries.jp/api/2.0/s-callauth?id=5JXRMRikltbDDB8456upltSmnBU8YZgov7Noiao7',
  urlDomestic: 'https://asp.ostiaries.jp/api/2.0/s-callauth?id=glF6DHmo0df4QuYuvcYLWhkDKK5q79oKp5p7aUh9'
}
export const SERVICE_OFFERWALL = {
  SMAAD: 1,
  MYCHIPS: 2
}
